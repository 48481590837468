:root {
  --main-color: #069cbd;
  --primary-color: #373866;
}
/* home page style*/
.home {
}
.navbar {
  padding: 0;
}
.navbar .navbar-brand img {
  width: 6rem;
}

/*footer style start*/
footer {
  /* background-color: var(--main-color) !important; */
  border-top: 1px solid var(--main-color);
}
.footerBottom {
}
footer {
  justify-content: start;
  direction: rtl;
  p {
    text-align: justify;
  }
}

/*footer style end*/

/* banner section start*/

.banner {
  height: 100vh;
  min-height: 650px;

  .widget {
    position: relative;
    max-width: 650px;
    width: 95%;
    margin: 0 auto;
    border-radius: 50px;

    border: 1px solid #000;
    padding: 20px 0;
    margin-top: -50px;
    z-index: 999;
    background: #fff;
    box-shadow: 0 0 30px #000;

    .widget-item {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;
      border-right: 1px solid #000;

      &:last-child {
        border-right: 0;
      }

      h2,
      p {
        margin: 0;
      }

      p {
        opacity: 0.7;
        font-size: 14px;
      }
    }
  }

  &-title {
    font-size: 2.4rem;
    font-weight: bold;
    opacity: 0.8;
  }

  &-mini {
    min-height: 24rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    background: lighten(gray, 20%);
  }
}

.banner .container {
  height: 100%;

  align-items: center;

  .infos {
    margin-top: 20px;

    .subtitle {
      margin-bottom: 0;
      font-size: 25px;
      font-weight: 500;
    }

    .title {
      margin-bottom: 0px;
      font-size: 54px;
      font-weight: bold;
    }

    p {
      margin-top: -5px;
      font-size: 22px;
    }

    .btn {
      display: inline-block;
      margin: 0 2px;
    }
    .btn-blue {
      background-color: var(--main-color);
      color: #fff;
      font-weight: bold;
    }
    .btnDarkBlue {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
}

/* banner section end*/

/* map section start*/
.mapSection {
  width: 100%;
  iframe {
    width: 100%;
    height: 22rem;
  }
}

/* map section end*/
